import { Career, Skill } from '../../models';

export function parseCareer(node: any): Career {
  return {
    id: node.id,
    name: node.name,
    description: node.description,
    location: node.location[0],
    position: node.position[0],
    salary: node.salary,
    skills: node.skills.map(parseSkill),
  };
}

export function parseCareers(nodes: any[]): Career[] {
  return nodes.map(parseCareer);
}

const SKILL_REGEX = /^([a-zA-Z0-9-\.#\(\)\s]+)\s\[\[(\d)\]\]$/i;

function parseSkill(skill: string): Skill {
  const match = SKILL_REGEX.exec(skill);
  if (!match) {
    throw new Error('Skill does not match regular expression!');
  }

  const [, name, rating] = match;
  return {
    name,
    rating: +rating,
  };
}
