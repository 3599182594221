import { graphql, PageProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  CareerPanel,
  CenteredContainer,
  NarrowCenteredContainer,
  PositionIcon,
  RecruitmentProcess,
  Tag,
} from '../components';
import { parseCareers } from '../helpers';
import { bgBannerLarge } from '../images';
import { Career } from '../models';
import { countBy } from 'lodash-es';

const ALL_TYPES_VALUE = '##ALL_TYPES#';
const ALL_LOCATIONS_VALUE = '##ALL_LOCATIONS#';

const RADIO_CLASS = 'w-0 invisible';

const TAG_CLASS = 'border border-primary bg-white';

const SELECTED_TAG_CLASS = `${TAG_CLASS} !bg-primary`;

interface TypeTagProps {
  selected: boolean;
  children: React.ReactNode;
  onSelect: () => void;
}

const Banner = () => (
  <div
    className="h-[calc(100%+var(--full-menu-height))] w-full absolute -z-50 top-[calc(-1*var(--full-menu-height))]"
    style={{ backgroundImage: `url("${bgBannerLarge}")` }}
  />
);

const TypeTag = ({ selected, children, onSelect }: TypeTagProps) => {
  return (
    <label>
      <input
        className={RADIO_CLASS}
        type="radio"
        name="position"
        value={ALL_TYPES_VALUE}
        checked={selected}
        onChange={() => onSelect()}
      />
      <Tag className={selected ? SELECTED_TAG_CLASS : TAG_CLASS}>
        {children}
      </Tag>
    </label>
  );
};

const getAllPropertyValuesWithCount = (
  careers: Career[],
  prop: keyof Career
): Array<[string, number]> => Object.entries(countBy(careers, prop));

const getNonZeroPropertyValuesWithCount = (
  careers: Career[],
  prop: keyof Career
): Array<[string, number]> =>
  getAllPropertyValuesWithCount(careers, prop).filter(([, v]) => !!v);

const Careers = ({ data }: PageProps) => {
  const { t } = useTranslation();
  const nodes = (data as any).allContentfulCareer.edges;
  const careers = useMemo(
    () => parseCareers(nodes.map((n: any) => n.node)),
    [nodes]
  );
  const [typeFilter, setTypeFilter] = useState(ALL_TYPES_VALUE);
  const [locationFilter, setLocationFilter] = useState(ALL_LOCATIONS_VALUE);
  const careersFiltered = careers.filter((career) => {
    let match = true;
    if (typeFilter !== ALL_TYPES_VALUE) {
      match = career.position === typeFilter;
    }
    if (locationFilter !== ALL_LOCATIONS_VALUE) {
      match &&= career.location === locationFilter;
    }
    return match;
  });

  const types = getNonZeroPropertyValuesWithCount(careersFiltered, 'position');
  const locations = getNonZeroPropertyValuesWithCount(
    careersFiltered,
    'location'
  );

  const hasCareers = !!careers.length;

  return (
    <>
      <Helmet>
        <title>{t('careers.page-title')}</title>
      </Helmet>
      {hasCareers && (
        <>
          <section className="text-white bg-cover relative" id="banner">
            <Banner />
            <NarrowCenteredContainer className="py-28 text-center">
              <header className="text-primary font-secondary font-black uppercase text-xs md:text-2xl mb-4 md:mb-8">
                {t('careers.banner.text-1')}
              </header>
              <p className="font-secondary font-black md:uppercase text-[30px] md:text-6xl leading-tight mb-4 md:mb-11">
                {careers.length
                  ? t('careers.banner.text-2', { count: careers.length })
                  : t('careers.banner.text-2-alt')}
              </p>
              <p className="md:text-[2.25rem] leading-snug">
                {t('careers.banner.text-3')}
              </p>
            </NarrowCenteredContainer>
          </section>
          <section className="padded-section bg-[color:rgba(var(--color-footer-text),.4)]">
            <NarrowCenteredContainer>
              <h2 className="text-5xl font-secondary text-header">
                {t('careers.open-positions-list.title')}
              </h2>
              <ul className="flex flex-row flex-wrap gap-4 md:gap-8 mt-9 mb-8">
                <li>
                  <TypeTag
                    selected={typeFilter === ALL_TYPES_VALUE}
                    onSelect={() => setTypeFilter(ALL_TYPES_VALUE)}
                  >
                    {t('careers.open-positions-list.all-types', {
                      count: careersFiltered.length,
                    })}
                  </TypeTag>
                </li>
                {types.map(([name, count]) => (
                  <li key={name}>
                    <TypeTag
                      selected={typeFilter === name}
                      onSelect={() => setTypeFilter(name)}
                    >
                      <PositionIcon
                        className="inline-block h-4"
                        position={name}
                      />
                      {name} ({count})
                    </TypeTag>
                  </li>
                ))}
              </ul>
              <ul className="flex flex-row flex-wrap gap-4 md:gap-8 mt-9 mb-8">
                <li>
                  <TypeTag
                    selected={locationFilter === ALL_LOCATIONS_VALUE}
                    onSelect={() => setLocationFilter(ALL_LOCATIONS_VALUE)}
                  >
                    {t('careers.open-positions-list.all-locations', {
                      count: careersFiltered.length,
                    })}
                  </TypeTag>
                </li>
                {locations.map(([name, count]) => (
                  <li key={name}>
                    <TypeTag
                      selected={locationFilter === name}
                      onSelect={() => setLocationFilter(name)}
                    >
                      {name} ({count})
                    </TypeTag>
                  </li>
                ))}
              </ul>
              {careersFiltered.map((career: Career) => (
                <CareerPanel key={career.id} career={career} />
              ))}
            </NarrowCenteredContainer>
          </section>
          <RecruitmentProcess />
        </>
      )}
      {!hasCareers && (
        <>
          <section className="text-white bg-cover relative" id="banner">
            <Banner />
          </section>
          <section className="h-[calc(100vh-var(--compact-menu-height))] md:h-auto flex md:block justify-center items-center md:py-80">
            <CenteredContainer className="text-center">
              <h2
                className="text-4xl md:text-6xl md:leading-[1.2] font-bold text-header"
                dangerouslySetInnerHTML={{ __html: t('careers.no-careers') }}
              />
            </CenteredContainer>
          </section>
        </>
      )}
    </>
  );
};

export default Careers;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulCareer(filter: { visible: { eq: true } }) {
      edges {
        node {
          id
          name
          description {
            raw
          }
          location
          position
          salary
          skills
        }
      }
    }
  }
`;
